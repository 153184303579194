$(document).ready(function() {

    /**
     * Desktop: Flavor switcher
     */
    $("a.x-btn").click(function(e) {
        e.preventDefault();
        var flavor = $(this).attr("data-flavor");
        var openState = $("a.x-btn.active").length;
        if (openState === 0) {
            $('html, body').animate({
                scrollTop: $("#flavors_section").offset().top
            }, 500);
        }
        $(".x-btn.active:not([data-flavor='" + flavor + "'])").removeClass("active");
        $(this).addClass("active");
        $(".description").hide();
        $(".description#" + flavor).show();
    });

    /**
     * Scroll to flavor sections on tap
     */
    $('.flavorjump').on('click', function(e) {
        e.preventDefault();

        var target = $("#flavors_section");

        $('html, body').stop().animate({
            'scrollTop': target.offset().top
        }, 900, 'swing');
    });

});
